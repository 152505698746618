import React from "react";
import styled from "styled-components";
import PageContainer from "./PageContainer";
import Page from "./Page";

const StyledMiddleColumn = styled.div`
  background: transparent;
  display: flex;
  align-items: center;
  text-align: center;
`;

const MiddleColumn = ({ titles, pageChanged }) => {
  return (
    <StyledMiddleColumn>
      <Page
        titles={titles}
        pageChanged={newIndex => {
          console.log("newIndex", newIndex);
          pageChanged(newIndex);
        }}
      />
    </StyledMiddleColumn>
  );
};

export default MiddleColumn;
