import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import bgImage1 from "../assets/images/bgImage1.jpeg";
import bgImage2 from "../assets/images/bgImage2.jpeg";
import {
  useSpring,
  animated,
  useChain,
  useTransition,
  config
} from "react-spring";
import { useKeyPress, useScrollEvent } from "../utils/customHooks";

const StyledPageContainer = styled(animated.div)`
  position: absolute;
  z-index: -10;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const StyledBackgroundImage = styled(animated.img)`
  width: 100%;
  height: 100%;
`;

const PageContainer = () => {
  const [animated, setAnimated] = useState(false);
  const [animated2, setAnimated2] = useState(false);
  const [index, setIndex] = useState(0);
  const leftKeyPressed = useKeyPress("ArrowLeft");
  const rightKeyPressed = useKeyPress("ArrowRight");
  const firstY = 0;
  const lastY = window.innerHeight;

  const makeSmallAndSlideOutDown = useSpring({
    to: async (next, cancel) => {
      await next({
        width: animated ? `${60}%` : `${100}%`,
        height: animated ? `${60}%` : `${100}%`
      });
      await next({
        transform: animated
          ? `translate3d(${0}px, ${lastY}px, ${0}px)`
          : `translate3d(${0}px, ${firstY}px, ${0}px)`
      });
    },
    delay: 200,
    config: config.slow
  });

  const slideInUpAndMakeBig = useSpring({
    to: async (next, cancel) => {
      await next({
        transform: animated2
          ? `translate3d(${0}px, ${firstY}px, ${0}px)`
          : `translate3d(${0}px, ${-2000}px, ${0}px)`
      });
      await next({
        width: animated2 ? `${100}%` : `${60}%`,
        height: animated2 ? `${100}%` : `${60}%`
      });
    },
    from: {
      transform: animated2
        ? `translate3d(${0}px, ${firstY}px, ${0}px)`
        : `translate3d(${0}px, ${-2000}px, ${0}px)`
    },
    delay: 200,
    config: config.slow
  });

  const makeContainerSmall = useSpring({
    top: animated ? "auto" : 0,
    left: animated ? "auto" : 0,
    bottom: animated ? "auto" : 0,
    delay: 200
  });

  const makeContainerBig = useSpring({
    top: animated2 ? 0 : "auto",
    left: animated2 ? 0 : "auto",
    bottom: animated2 ? 0 : "auto",
    delay: 2000
  });

  useEffect(() => {
    if (leftKeyPressed) {
      setAnimated(true);
      setTimeout(() => {
        setIndex(1);
      }, 2000);
    }

    if (rightKeyPressed) {
      setAnimated2(true);
    }
  }, [leftKeyPressed, rightKeyPressed]);

  return (
    <StyledPageContainer style={makeContainerSmall && makeContainerBig}>
      {index === 0 && (
        <StyledBackgroundImage
          style={makeSmallAndSlideOutDown}
          src={bgImage1}
        />
      )}
      {index === 1 && (
        <StyledBackgroundImage style={slideInUpAndMakeBig} src={bgImage2} />
      )}
    </StyledPageContainer>
  );
};

export default PageContainer;
