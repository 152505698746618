import React from "react";
import styled from "styled-components";
import bgImage1 from "./assets/images/bgImage1.jpeg";
import MainPage from "./components/MainPage";

const StyledApp = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  color: white;
`;

function App() {
  return (
    <StyledApp>
      <MainPage />
    </StyledApp>
  );
}

export default App;
