import React, { useState } from "react";
import styled from "styled-components";
import LeftColumn from "./LeftColumn";
import MiddleColumn from "./MiddleColumn";
import RightColumn from "./RightColumn";

const StyledMainPage = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  height: 100%;
`;

const MainPage = () => {
  const titles = ["Design", "Photography", "Website", "Blog"];
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <StyledMainPage>
      <LeftColumn />
      <MiddleColumn
        titles={titles}
        pageChanged={newIndex => {
          console.log("newIndex2", newIndex);
          setActiveIndex(newIndex);
        }}
      />
      <RightColumn menuList={titles} activeIndex={activeIndex} />
    </StyledMainPage>
  );
};

export default MainPage;
