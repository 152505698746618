import React from "react";
import styled from "styled-components";
import logo from "../assets/icons/logo.svg";
import leftArrow from "../assets/icons/left-arrow.png";
import rightArrow from "../assets/icons/right-arrow.png";

const StyledLeftColumn = styled.div`
  background: transparent;
  text-align: right;
  display: grid;
  grid-template-rows: 1fr auto;
  padding-left: 50px;
`;

const StyledLogo = styled.img`
  height: 120px;
  margin-top: 50px;
`;

const StyledArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
`;

const StyledLeftArrow = styled.img`
  width: 80px;
  height: 80px;
`;
const StyledRightArrow = styled.img`
  width: 80px;
  height: 80px;
`;

const LeftColumn = () => {
  return (
    <StyledLeftColumn>
      <StyledLogo src={logo} />
      <StyledArrowContainer>
        <StyledLeftArrow src={leftArrow} />
        <StyledRightArrow src={rightArrow} />
      </StyledArrowContainer>
    </StyledLeftColumn>
  );
};

export default LeftColumn;
