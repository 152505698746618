import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import bgImage1 from "../assets/images/bgImage1.jpeg";
import bgImage2 from "../assets/images/bgImage2.jpeg";
import {
  useSpring,
  animated,
  useChain,
  useTransition,
  config
} from "react-spring";
import { useKeyPress } from "../utils/customHooks";

const StyledPage = styled(animated.div)`
  position: absolute;
  z-index: -10;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const StyledBackgroundImage = styled(animated.img)`
  width: 100%;
  height: 100%;
`;

const StyledImageContainer = styled(animated.div)`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled(animated.h1)`
  font-size: 7rem;
  color: white;
  position: absolute;
  left: 10%;
  z-index: 10;
`;

const Page = ({ titles, pageChanged }) => {
  const [index, setIndex] = useState(0);
  const [slide, setSlide] = useState(false);
  const images = [bgImage1, bgImage2, bgImage1, bgImage2];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keyDownHandler = ({ key }) => {
    if (key === "ArrowLeft" && index > 0) {
      setIndex(index - 1);
      setSlide(true);
    }

    if (key === "ArrowRight" && index < titles.length - 1) {
      setIndex(index + 1);
      setSlide(true);
    }

    console.log("index: ", index);
  };

  const scrollHandler = e => {
    console.log("scroll event: ", e);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    window.addEventListener("keydown", keyDownHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [keyDownHandler]);

  useEffect(() => {
    pageChanged(index);
  }, [index, pageChanged]);

  const slideInUpAndMakeBig = useSpring({
    to: async (next, cancel) => {
      await next({
        width: slide ? `${60}%` : `${100}%`,
        height: slide ? `${60}%` : `${100}%`
      });
      await next({
        transform: slide
          ? `translate3d(${0}px, ${-index * window.innerHeight}px, ${0}px)`
          : `translate3d(${0}px, ${0}px, ${0}px)`
      });
      await next({
        width: slide ? `${100}%` : `${60}%`,
        height: slide ? `${100}%` : `${60}%`
      });
    },
    config: config.slow
  });

  const slideUp = useSpring({
    transform: slide
      ? `translate3d(${0}px, ${-index * window.innerHeight}px, ${0}px)`
      : `translate3d(${0}px, ${0}px, ${0}px)`,
    config: config.slow,
    delay: 1300
  });

  // const transitions = useTransition(titles, null, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 }
  // });

  return (
    <StyledPage>
      {images &&
        images.map((image, i) => {
          return (
            <StyledImageContainer key={`image-container-${i}`}>
              <StyledTitle style={slideUp}>{titles && titles[i]}</StyledTitle>
              <StyledBackgroundImage src={image} style={slideInUpAndMakeBig} />
            </StyledImageContainer>
          );
        })}
    </StyledPage>
  );
};

export default Page;
