import React from "react";
import styled from "styled-components";
import lineIcon from "../assets/icons/baseline-remove-24px.svg";

const StyledRightColumn = styled.div`
  background: transparent;
  border-left: 1px solid rgba(28, 22, 22, 0.3);
`;

const StyledUl = styled.ul`
  list-style-type: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledLi = styled.li`
  padding-bottom: 20px;
  padding-left: 10px;
  font-weight: bold;
  font-size: 1.5em;
  color: ${props => (props.isActive ? "white" : "darkgray")};
`;

const RightColumn = ({ menuList, activeIndex }) => {
  return (
    <StyledRightColumn>
      <StyledUl>
        {menuList &&
          menuList.map((item, index) => {
            return <StyledLi isActive={index === activeIndex}>{item}</StyledLi>;
          })}
      </StyledUl>
    </StyledRightColumn>
  );
};

export default RightColumn;
